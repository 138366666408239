import {
  GET_REPORTS_SUCCESS,
  GET_SAVED_REPORTS_SUCCESS,
  GET_REPORT_SUCCESS,
  CLEAR_REPORT
} from '../actions/types';

const INITIAL_STATE = {
  reports: [],
  savedReports: [],
  report: {
    reportCreatedDate: 0,
    reportDescription: '',
    reportFile: '',
    reportKey: '',
    reportName: ''
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_REPORTS_SUCCESS:
      return { ...state, reports: action.payload };
    case GET_SAVED_REPORTS_SUCCESS:
      return { ...state, savedReports: action.payload };
    case GET_REPORT_SUCCESS:
      return { ...state,
        report: {
          reportCreatedDate: action.payload.profile.reportCreatedDate,
          reportDescription: action.payload.profile.reportDescription,
          reportFile: action.payload.profile.reportFile,
          reportKey: action.payload.profile.reportKey,
          reportName: action.payload.profile.reportName
        }
      }
    case CLEAR_REPORT:
      return { ...state, report: {} };
    default:
      return state;
  }
};
