import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getReports } from '../../actions';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import { SideNavigation } from '../Navigation';
import SemipolarSpinner from '@bit/bondz.react-epic-spinners.semipolar-spinner';

class Integrations extends Component {
  componentDidMount() {
    //this.unsubscribeReports = this.props.firebase.reports().onSnapshot(this.onReportsUpdate);
  }

  componentWillUnmount() {
    //this.unsubscribeReports();
  }

  onReportsUpdate = (querySnapshot) => {
    this.props.getReports(querySnapshot);
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <SideNavigation active="Integrations"/>
          <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4" style={{paddingTop: 78}}>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-5 pb-2">
              <h1 className="h5">
                Integrations
              </h1>
            </div>
            <div className="row">
            <SemipolarSpinner
color='#000000'
size='200'
/>
              <div className="col-md-4">
                <div className="card shadow-sm">
                  <div className="card-body">
                    <a href="http://appcenter.ms/users/levi-05/apps/HoopLife-Training" className="btn btn-primary">
                      App Center
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card shadow-sm">
                  <div className="card-body">
                    <div className="row">
                      <button className="btn btn-primary"></button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card shadow-sm">
                  <div className="card-body">
                    <div className="row">
                      <button className="btn btn-primary"></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => {
  return { state };
}

const condition = authUser => !!authUser;
withRouter(withFirebase(Integrations))
export default withAuthorization(condition)(connect(mapStateToProps, { getReports })(Integrations));
