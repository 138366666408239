import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Session';
import SignOutButton from '../SignOut';
import SavedReports from '../SavedReports';

class Navigation extends Component {
  render() {
    return (
      <div>
        <AuthUserContext.Consumer>
          {(authUser) =>
            authUser ? <NavigationAuth /> : null
          }
        </AuthUserContext.Consumer>
      </div>
    );
  }
}


const NavigationAuth = () => (
  <nav className="navbar fixed-top flex-md-nowrap p-0 shadow-sm" style={{backgroundColor: 'white', borderBottom: '1px solid rgba(0,0,0,.125)'}}>
        <a className="navbar-brand col-sm-1 col-md-1 mr-0" href="/" style={{backgroundColor: 'white', boxShadow: 'none', marginLeft: 5}}>
            <img className="rounded" src="https://i.ibb.co/5WsMWQf/Hoop-Life-Icon.jpg" width="45" height="45" />
        </a>
        <input className="form-control" type="text" placeholder="Search" aria-label="Search" style={{marginLeft: 15, width: 800, borderWidth: 1}} />
        <div style={{width:440, visibility: 'hidden'}}></div>
        <ul className="navbar-nav px-3">
          <li className="nav-item text-nowrap">
            <SignOutButton />
          </li>
        </ul>
      </nav>
);

const SideNavigation = (props) => {

  return (
    <nav className="col-md-2 d-none d-md-block sidebar" style={{borderRight: '1px solid rgba(0,0,0,.125)'}}>
              <div className="sidebar-sticky" style={{paddingTop: '2rem', backgroundColor: 'white'}}>
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <Link to={ROUTES.HOME} className={props.active==="Home" ? "nav-link active" : "nav-link"}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                      Dashboard <span className="sr-only">(current)</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={ROUTES.REPORTS} className={props.active==="Reports" ? "nav-link active" : "nav-link"}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bar-chart-2"><line x1="18" y1="20" x2="18" y2="10"></line><line x1="12" y1="20" x2="12" y2="4"></line><line x1="6" y1="20" x2="6" y2="14"></line></svg>
                      Reports
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={ROUTES.INTEGRATIONS} className={props.active==="Integrations" ? "nav-link active" : "nav-link"}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline></svg>
                      Integrations
                    </Link>
                  </li>
                </ul>
                <SavedReports />
              </div>
            </nav>

)
};

export default Navigation;

export { SideNavigation };
