import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import { withRouter } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { connect } from 'react-redux';
import { getUser } from '../../actions';

class SignIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      error: '',
      loading: false,
      userLoading: false
    };
  }

  componentDidMount() {
    const condition = authUser => authUser == null;
    this.setState({loading: true});
    this.listener = this.props.firebase.auth.onAuthStateChanged(
      authUser => {
        if (!condition(authUser) && !this.state.userLoading) {
          if(typeof this.props.permissions.access_app_portal !== 'undefined' && this.props.permissions.access_app_portal) {
            this.setState({loading: false});
            this.props.history.push(ROUTES.HOME);
          }
          else {
            this.setState({loading: false});
            //safety measure to deter a hacker or somebody who is too curious, directs to a fake error page
            this.props.history.push('/error/11nas02');
          }
        }
        else {
          this.setState({loading: false});
        }
      }
    );
  }

  componentWillUnmount() {
    this.listener();
  }

  onSubmit() {
  const { email, password } = this.state;

  this.setState({loading: true});

  this.props.firebase
    .doSignInWithEmailAndPassword(email, password)
    .then(() => {
      const { currentUser } = this.props.firebase.auth;

      this.setState({ email: '', password: '', error: '', userLoading: true });

      this.props.firebase.user(currentUser.uid).get().then(querySnapshot => {
        if(typeof querySnapshot.data().permissions !== 'undefined') {
          if(typeof querySnapshot.data().permissions.access_app_portal !== 'undefined' && querySnapshot.data().permissions.access_app_portal) {
              this.props.getUser(querySnapshot);
              this.setState({loading: false});
              this.props.history.push(ROUTES.HOME);
          }
          else {
            //safety measure to deter a hacker or somebody who is too curious, directs to a fake error page
            this.props.history.push('/error/99tr190');
            this.props.firebase.doSignOut();
            this.setState({loading: false});
          }
        }
      });

    })
    .catch(error => {
      this.setState({ error, loading: false });
    });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { email, password } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <div className="container-fluid login-background">
        <div className="row">
        {this.state.loading ?
          <div className="spinner-overlay fade-in">
            <div className="spinner-border spinner-red" role="status" style={{marginTop: 250}}>
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        : null
        }
          <main role="main" className="col-md-12" style={{paddingTop: 78}}>
            <div className="card card-signin my-5 col-sm-3 shadow-sm" style={{margin: 'auto'}}>
              <div className="card-body">
                <h5 className="card-title text-center">Sign In</h5>
                <hr />
                <form className="form-signin" onSubmit={()=> {return false}}>
                  <div className="form-label-group">
                    <label for="inputEmail">Email address</label>
                    <input
                      type="email"
                      name="email"
                      onChange={this.onChange}
                      value={email}
                      className="form-control"
                      placeholder="Email address"
                      required
                      autoFocus
                    />
                  </div>
                  <div className="form-label-group">
                    <label for="inputPassword">Password</label>
                    <input
                      type="password"
                      name="password"
                      onChange={this.onChange}
                      value={password}
                      className="form-control"
                      placeholder="Password"
                      required
                    />
                  </div>
                  <br />
                  <button className="btn btn-lg btn-primary btn-block" type="button" disabled={isInvalid} onClick={()=>this.onSubmit()}>
                    Sign in
                  </button>
                </form>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { permissions } = state.user.user;

  return { permissions };
};

const condition = authUser => authUser == null;
withRouter(SignIn);
export default withFirebase(connect(mapStateToProps, { getUser })(SignIn));
