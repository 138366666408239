import { combineReducers } from 'redux';
import UserReducer from './UserReducer';
import StatisticsReducer from './StatisticsReducer';
import ReportsReducer from './ReportsReducer';
import GlobalReducer from './GlobalReducer';

export default combineReducers({
  user: UserReducer,
  statistics: StatisticsReducer,
  reports: ReportsReducer,
  global: GlobalReducer
});
