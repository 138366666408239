import {
  GET_USERS_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
  loading: false,
  users: {
    users: [],
    totalUsers: 0,
    maleUsers: 0,
    femaleUsers: 0
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USERS_SUCCESS:
      return {...state,
        users: {
          users: action.payload.users,
          totalUsers: action.payload.totalUsers,
          maleUsers: action.payload.maleUsers,
          femaleUsers: action.payload.femaleUsers
        }
    };
    default:
      return state;
  }
};
