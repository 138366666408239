import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getReport } from '../../actions';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import { SideNavigation } from '../Navigation';
import { dateFormat } from '../../utilities/Date';

class ViewReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
    this.unsubscribeReport = null;
  }

  componentDidMount() {
    const reportKey  = this.props.match.params.reportKey;

    this.props.firebase.report(reportKey).get().then(querySnapshot => {
      if(typeof querySnapshot.data() !== 'undefined') {
        this.unsubscribeReport = this.props.firebase.report(reportKey).onSnapshot(this.onReportUpdate);
      }
      else {
        this.unsubscribeReport = null;
        this.props.history.push('/error/404');
      }
    });
  }

  componentWillUnmount() {
    if(this.unsubscribeReport !== null) {
      this.unsubscribeReport();
    }
  }

  onReportUpdate = (querySnapshot) => {
    this.props.getReport(querySnapshot);
  }

  render() {
    const { reportName, reportDescription } = this.props.report;

    return (
      <div className="container-fluid">
        <div className="row">
          <SideNavigation active="Reports"/>
          <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4" style={{paddingTop: 78}}>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-5 pb-2">
          </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card shadow-sm">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <h5>{reportName}</h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <p>{reportDescription}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { report } = state.reports;

  return { report };
}

const condition = authUser => !!authUser;
withRouter(withFirebase(ViewReport))
export default withAuthorization(condition)(connect(mapStateToProps, { getReport })(ViewReport));
