import {
  GET_USER_SUCCESS,
  GET_USER_FAILED
} from './types';

export const getUser = (querySnapshot) => {
  return (dispatch) => {

    if(querySnapshot.exists) {
      dispatch({ type: GET_USER_SUCCESS, payload: querySnapshot.data() });
    }
    else {
      dispatch({ type: GET_USER_FAILED, payload: 'User Information Does Not Exist' });
    }

  }
};
