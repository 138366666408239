import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getSavedReports } from '../../actions';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';

class SavedReportsBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  componentDidMount() {
    const { currentUser } = this.props.firebase.auth;
    this.unsubscribeSavedReports = this.props.firebase.savedReports(currentUser.uid).onSnapshot(this.onSavedReportsUpdate);
  }

  componentWillUnmount() {
    this.unsubscribeSavedReports();
  }

  onSavedReportsUpdate = (querySnapshot) => {
    this.props.getSavedReports(querySnapshot);
  }

  render() {
    return (
      <>
      <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
        <span>Saved reports</span>
        <Link className="d-flex align-items-center text-muted" to={ROUTES.REPORTS}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
        </Link>
      </h6>
      <ul className="nav flex-column mb-2">
        {this.props.savedReports.map(item =>
          <li className="nav-item" key={item.key}>
            <Link className="nav-link" to={'/viewReport/' + item.savedReportKey}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
              {item.savedName}
            </Link>
          </li>
        )}
      </ul>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { savedReports } = state.reports;

  return { savedReports };
}
const SavedReports = compose(
  withRouter,
  withFirebase,
)(SavedReportsBase);


export default connect(mapStateToProps, { getSavedReports })(SavedReports);
