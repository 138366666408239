import {
  GET_USERS_SUCCESS,
  GLOBAL_ERROR
} from './types';
import { map } from 'lodash';

export const getAllUsers = (querySnapshot) => {
  return (dispatch) => {

    const usersArray = map(querySnapshot, (val) => {
        return { ...val };
    });


    var i = 0;

    const newArray = [];

    usersArray.forEach(item => {
      i++;
      var hasUserInfo = item.hasOwnProperty("userInfo");
      if(!hasUserInfo) {
        const date = new Date(), time = date.getTime();
        const userKey = Object.keys(querySnapshot)[i - 1];
        const errorPayload = {errorText: 'User Error: User has no userInfo', errorTime: time, errorPayload: userKey };

        dispatch({ type: GLOBAL_ERROR, errorPayload });
      }
      else {
        newArray.push(item);
      }
    });
    const totalUsers = usersArray.length;
    const maleUsers = newArray.filter(newArray => newArray.userInfo.gender === 'Male').length,
    femaleUsers = newArray.filter(newArray => newArray.userInfo.gender === 'Female').length;

    const payload = { users: usersArray, totalUsers, maleUsers, femaleUsers };

    dispatch({ type: GET_USERS_SUCCESS, payload });
  }
};
