import React from 'react';
import {
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom';
import Navigation from '../Navigation';
import SignInPage from '../SignIn';
import Home from '../Home';
import Reports from '../Reports';
import AddReport from '../AddReport';
import ViewReport from '../ViewReport';
import Integrations from '../Integrations';
import NoMatch from '../NoMatch';
import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';

const App = () => (
  <BrowserRouter>
    <div>
      <Navigation />
      <Switch>
        <Route exact path={ROUTES.HOME} component={Home} />
        <Route path={ROUTES.SIGN_IN} component={SignInPage} />
        <Route path={ROUTES.REPORTS} component={Reports} />
        <Route path={ROUTES.ADD_REPORT} component={AddReport} />
        <Route path={ROUTES.VIEW_REPORT} component={ViewReport} />
        <Route path={ROUTES.INTEGRATIONS} component={Integrations} />
        <Route component={NoMatch} />
      </Switch>
    </div>
  </BrowserRouter>
);

export default withAuthentication(App);
