import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import { SideNavigation } from '../Navigation';
import FileUploader from "react-firebase-file-uploader";

class AddReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      description: "",
      isUploading: false,
      progress: 0,
      fileUrl: '',
      success: false
    };
  }

handleChangeTitle = event =>
  this.setState({ title: event.target.value });

onChange = event => {
  this.setState({ [event.target.name]: event.target.value });
};


handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });

handleProgress = progress => this.setState({ progress });

handleUploadError = error => {
  this.setState({ isUploading: false });
  console.error(error);
};

handleUploadSuccess = filename => {
  this.setState({ avatar: filename, progress: 100, isUploading: false });
  this.props.firebase
    .uploadRef("reports")
    .child(filename)
    .getDownloadURL()
    .then(url => this.setState({fileUrl: url}));
};

  onSubmit = event => {
    const { title, fileUrl, description } = this.state;
    const { currentUser } = this.props.firebase.auth;

    this.setState({loading: true});

    const d = new Date(), time = d.getTime();
    const reportKey = 'RP' + time;
    this.props.firebase.report(reportKey)
    .set({
      profile: {
        reportKey,
        reportFile: fileUrl,
        reportCreatedDate: time,
        reportName: title,
        reportDescription: description,
        reportCreator: currentUser.uid,
        reportCreatorExtra: this.props.userName
      }
    })
    .then(() => this.setState({loading: false, fileUrl: '', title: '', success: true}))
    .catch(error => console.log(error));

    event.preventDefault();
  };

  render() {
    const isInvalid = this.state.title === '' || this.state.title.length < 5 ||
    this.state.description === '' || this.state.description.length < 5 ||
    this.state.fileUrl.length < 1;
    return (
      <div className="container-fluid">
        <div className="row">
          <SideNavigation active="Reports"/>
          <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4" style={{paddingTop: 78}}>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2">
              <h1 className="h5">
                Add Report
              </h1>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card shadow-sm">
                  <div className="card-body">
                  {this.state.success ?
                    <div className="alert alert-success" role="alert">
                      Report successfully submitted!
                      <Link to={ROUTES.REPORTS}>
                        Go Back?
                      </Link>
                    </div>
                  :
                    null
                  }
                  <form onSubmit={this.onSubmit}>
                    <label>Report Title:</label>
                    <input
                      type="text"
                      value={this.state.title}
                      name="title"
                      onChange={this.onChange}
                      className="form-control"
                      placeHolder="Min 5 characters"
                      style={{margin: '0px 0 20px 0'}}
                    />
                    <label>Description:</label>
                    <textArea
                      className="form-control"
                      name="description"
                      value={this.state.description}
                      onChange={this.onChange}
                      type="text"
                      placeholder="Report Description"
                      style={{margin: '0px 0 20px 0'}}
                    />
                    <label>File:</label>
                      <FileUploader
                        accept="media_types"
                        storageRef={this.props.firebase.uploadRef("reports")}
                        onUploadStart={this.handleUploadStart}
                        onUploadError={this.handleUploadError}
                        onUploadSuccess={this.handleUploadSuccess}
                        onProgress={this.handleProgress}
                        disabled={this.state.title === '' || this.state.title.length < 5}
                        style={{margin: '0px 0 20px 0'}}
                        className="form-control-file"
                      />
                    <br />
                    <button className="btn btn-primary" type="submit" disabled={isInvalid} style={{width: 200}}>
                      Submit Report
                    </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { userName } = this.state.user.profile;

  return { userName };
}

const condition = authUser => !!authUser;
withRouter(withFirebase(AddReport))
export default withAuthorization(condition)(connect(mapStateToProps, { })(AddReport));
