import {
  GET_USER_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
  loading: false,
  user: {
    profile: {

    },
    permissions: {

    }
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USER_SUCCESS:
      return {...state,
      user: {
        profile: {
          userName: action.payload.profile.userName
        },
        permissions: {
          access_app_portal: action.payload.permissions.access_app_portal
        }
      }
    };
    default:
      return state;
  }
};
