import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/storage';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
  constructor(props) {
    app.initializeApp(config);
    this.auth = app.auth();
    this.firestore = app.firestore();
    this.db = app.database();
    this.storage = app.storage();
  }

  // *** Auth API ***
  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut()
              .then(() => {
                return true;
              });

  // *** Users API ***
  users = () => this.db.ref('/users');
  user = uid => this.firestore.collection('users').doc(uid);

  // *** File upload API ***
  uploadRef = ref => this.storage.ref(ref);

  // *** Reports API ***
  report = key => this.firestore.collection('reports').doc(key);
  reports = () => this.firestore.collection('reports');
  savedReports = uid => this.firestore.collection('savedReports').where('profile.savedBy', '==', uid);
  savedReport = key => this.firestore.collection('savedReports').doc(key);
}

export default Firebase;
