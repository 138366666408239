import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getAllUsers } from "../../actions";
import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";
import { SideNavigation } from "../Navigation";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { dateFormat } from "../../utilities/Date";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    if (this.props.totalUsers === 0) {
      this.getUsers();
    }
  }

  getUsers() {
    this.setState({ loading: true });
    this.props.firebase
      .users()
      .once("value")
      .then((snapshot) => {
        const users = snapshot.val();

        this.props.getAllUsers(users);

        this.setState({ loading: false });
      });
  }

  render() {
    return (
      <div className="container-fluid" id="main">
        <div className="row">
          <SideNavigation active="Home" />
          <main
            role="main"
            className="col-md-9 ml-sm-auto col-lg-10 px-4"
            style={{ paddingTop: 78 }}
          >
            {this.state.loading ? (
              <div className="spinner-overlay fade-in">
                <div className="spinner-border spinner-red" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : null}
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-5 pb-2">
              <h1 className="h5">
                User Stats
                <i
                  className="fas fa-sync-alt"
                  style={{ marginLeft: 10, cursor: "pointer" }}
                  onClick={() => this.getUsers()}
                ></i>
              </h1>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="card shadow-sm">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-auto">
                        <i className="fas fa-users fa-4x"></i>
                      </div>
                      <div className="col-md-auto">
                        <h2 style={{ marginBottom: 0 }}>
                          {this.props.totalUsers}
                        </h2>
                        <p className="text-secondary">Total Users</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card shadow-sm">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-auto">
                        <i
                          className="fas fa-male fa-4x"
                          style={{ color: "#1B65BE" }}
                        ></i>
                      </div>
                      <div className="col-md-auto">
                        <h2 style={{ marginBottom: 0 }}>
                          {this.props.maleUsers}
                        </h2>
                        <p className="text-secondary">Male Users</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card shadow-sm">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-auto">
                        <i
                          className="fas fa-female fa-4x"
                          style={{ color: "#EF7187" }}
                        ></i>
                      </div>
                      <div className="col-md-auto">
                        <h2 style={{ marginBottom: 0 }}>
                          {this.props.femaleUsers}
                        </h2>
                        <p className="text-secondary">Female Users</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2">
                  <h1 className="h5">Weekly Registrations</h1>
                </div>
                <div className="card shadow" style={{ padding: 20 }}>
                  <WeeklyRegistrations users={this.props.users} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2">
                  <h1 className="h5">Monthly Registrations</h1>
                </div>
                <div className="card shadow" style={{ padding: 20 }}>
                  <MonthlyRegistrations users={this.props.users} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2">
                  <h1 className="h5">All Time Registrations</h1>
                </div>
                <div className="card shadow" style={{ padding: 20 }}>
                  <AllTimeRegistrations users={this.props.users} />
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

class WeeklyRegistrations extends Component {
  render() {
    const d = new Date(),
      time = d.getTime() - 7 * 86400000;
    const weeklyUsers = this.props.users.filter((user) =>
      user.userInfo ? user.userInfo.registerDate > time : null
    );
    const data = [];

    for (var i = 1; i < 8; i++) {
      const daysAgo = d.getTime() - i * 86400000;
      const start = new Date(daysAgo);
      start.setHours(0, 0, 0, 0);
      const startTime = start.getTime();
      const end = new Date(daysAgo);
      end.setHours(23, 59, 59, 999);
      const endTime = end.getTime();

      const filteredUsers = weeklyUsers.filter((user) =>
        user.userInfo
          ? user.userInfo.registerDate > startTime &&
            user.userInfo.registerDate < endTime
          : null
      );
      data.push({
        name: dateFormat(new Date(daysAgo), "#MMMM# #DD#"),
        Amount: filteredUsers.length,
      });
    }

    data.reverse();

    return (
      <LineChart
        width={600}
        height={300}
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <XAxis dataKey="name" />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="Amount"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    );
  }
}

function toDays(d) {
  d = d || 0;
  return d / 24 / 60 / 60 / 1000;
}

class MonthlyRegistrations extends Component {
  render() {
    const d = new Date(),
      time = d.getTime() - 4 * 2629800000;
    const monthlyUsers = this.props.users.filter((user) =>
      user.userInfo ? user.userInfo.registerDate > time : null
    );

    const data = [];

    for (var i = 1; i < 5; i++) {
      const currentMonthlyTime = d.getTime() - i * 2629800000;
      const currentMonthlyDate = new Date(currentMonthlyTime);
      const month = Number(currentMonthlyDate.getMonth());
      const newYear = dateFormat(new Date(currentMonthlyDate), "#YYYY#");

      const d5 = new Date(newYear, month, 1);
      const firstTimeMonth = d5.getTime();

      const daysInMonth = toDays(
        new Date(newYear, month + 1, 1) - new Date(newYear, month, 1)
      );
      const lastDateMonth = new Date(newYear, month, daysInMonth);
      const lastTimeMonth = lastDateMonth.getTime();

      const start = new Date(firstTimeMonth);
      start.setHours(0, 0, 0, 0);
      const startTime = start.getTime();
      const end = new Date(lastTimeMonth);
      end.setHours(23, 59, 59, 999);
      const endTime = end.getTime();

      const filteredUsers = monthlyUsers.filter((user) =>
        user.userInfo
          ? user.userInfo.registerDate > startTime &&
            user.userInfo.registerDate < endTime
          : null
      );

      data.push({
        name:
          dateFormat(new Date(firstTimeMonth), "#MMM# #DD#") +
          " - " +
          dateFormat(new Date(lastTimeMonth), "#MMM# #D#"),
        Amount: filteredUsers.length,
      });
    }

    data.reverse();

    return (
      <LineChart
        width={600}
        height={300}
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <XAxis dataKey="name" />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="Amount"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    );
  }
}

class AllTimeRegistrations extends Component {
  render() {
    const d = new Date(),
      time = d.getTime() - 4 * 2629800000;
    const monthlyUsers = this.props.users;
    const data = [];

    for (var i = 1; i < 9; i++) {
      const currentMonthlyTime = d.getTime() - i * 2629800000;
      const currentMonthlyDate = new Date(currentMonthlyTime);
      const month = Number(currentMonthlyDate.getMonth());
      const newYear = dateFormat(new Date(currentMonthlyDate), "#YYYY#");
      const d5 = new Date(newYear, month, 1);
      const firstTimeMonth = d5.getTime();

      const daysInMonth = toDays(
        new Date(newYear, month + 1, 1) - new Date(newYear, month, 1)
      );
      const lastDateMonth = new Date(newYear, month, daysInMonth);
      const lastTimeMonth = lastDateMonth.getTime();

      const start = new Date(firstTimeMonth);
      start.setHours(0, 0, 0, 0);
      const startTime = start.getTime();
      const end = new Date(lastTimeMonth);
      end.setHours(23, 59, 59, 999);
      const endTime = end.getTime();

      const filteredUsers = monthlyUsers.filter((user) =>
        user.userInfo
          ? user.userInfo.registerDate > startTime &&
            user.userInfo.registerDate < endTime
          : null
      );
      data.push({
        name: dateFormat(new Date(firstTimeMonth), "#MMM#/#YY#"),
        Amount: filteredUsers.length,
      });
    }

    data.reverse();

    return (
      <LineChart
        width={900}
        height={300}
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <XAxis dataKey="name" />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="Amount"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    );
  }
}

const mapStateToProps = (state) => {
  const { users, totalUsers, maleUsers, femaleUsers } = state.statistics.users;

  return { users, totalUsers, maleUsers, femaleUsers };
};

const condition = (authUser) => !!authUser;
withRouter(withFirebase(Home));
export default withAuthorization(condition)(
  connect(mapStateToProps, { getAllUsers })(Home)
);
