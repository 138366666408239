import {
  GET_REPORTS_SUCCESS,
  GET_SAVED_REPORTS_SUCCESS,
  GET_REPORT_SUCCESS,
  GET_REPORT_FAILED,
  CLEAR_REPORT
} from './types';

export const getReports = (querySnapshot) => {

  const reports = [];

  querySnapshot.forEach((doc) => {
    const { reportCreatedDate, reportFile, reportKey, reportName } = doc.data().profile;

    reports.push({
      key: reportKey,
      reportCreatedDate,
      reportFile,
      reportKey,
      reportName
    });
  });

  return {
    type: GET_REPORTS_SUCCESS,
    payload: reports
  };
};

export const getSavedReports = (querySnapshot) => {

  const savedReports = [];

  querySnapshot.forEach((doc) => {
    const { savedBy, savedKey, savedReportKey, savedName } = doc.data().profile;

    savedReports.push({
      key: savedReportKey,
      savedBy,
      savedKey,
      savedReportKey,
      savedName
    });
  });

  return {
    type: GET_SAVED_REPORTS_SUCCESS,
    payload: savedReports
  };
};

export const getReport = (querySnapshot) => {
  return (dispatch) => {

    if(querySnapshot.exists) {
      dispatch({ type: GET_REPORT_SUCCESS, payload: querySnapshot.data() });
    }
    else {
      dispatch({ type: GET_REPORT_FAILED, payload: 'Report Information Does Not Exist' });
    }

  }
};

export const clearReport = () => {
  return {
    type: CLEAR_REPORT
  };
};
