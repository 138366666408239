import { GLOBAL_ERROR, CLEAR_ERROR } from "../actions/types";

const INITIAL_STATE = {
  error: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GLOBAL_ERROR:
      return {
        ...state,
        error: {
          errorText: action.payload ? action.payload.errorText : "",
          errorTime: action.payload ? action.payload.errorTime : "",
          errorPayload: action.payload ? action.payload.errorPayload : ""
        }
      };
    case CLEAR_ERROR:
      return { ...state, report: {} };
    default:
      return state;
  }
};
