//Get User
export const GET_USER_SUCCESS = 'get_user_success';
export const GET_USER_FAILED = 'get_user_failed';

//Stats
export const GET_USERS_SUCCESS = 'get_users_success';

//Reports
export const GET_REPORTS_SUCCESS = 'get_reports_success';
export const GET_SAVED_REPORTS_SUCCESS = 'get_saved_reports_success';
export const GET_REPORT_SUCCESS = 'get_report_success';
export const GET_REPORT_FAILED = 'get_report_failed';
export const CLEAR_REPORT = 'clear_report';

//Errors
export const GLOBAL_ERROR = 'global_error';
export const CLEAR_ERROR = 'clear_error';
