import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getReports } from '../../actions';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import { SideNavigation } from '../Navigation';
import { dateFormat } from '../../utilities/Date';

class Reports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.unsubscribeReports = this.props.firebase.reports().onSnapshot(this.onReportsUpdate);
  }

  componentWillUnmount() {
    this.unsubscribeReports();
  }

  onReportsUpdate = (querySnapshot) => {
    this.props.getReports(querySnapshot);
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <SideNavigation active="Reports"/>
          <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4" style={{paddingTop: 78}}>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-5 pb-2">
              <h1 className="h5">
                Reports
                <Link to={ROUTES.ADD_REPORT}>
                  <i className="fas fa-plus" style={{marginLeft: 10, cursor: 'pointer', color: 'black'}}>
                  </i>
                </Link>
              </h1>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card shadow-sm">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        <h5>Report Name</h5>
                      </div>
                      <div className="col-md-2">
                        <h5>Report Link</h5>
                      </div>
                      <div className="col-md-4">
                        <h5>Report Created On</h5>
                      </div>
                      <div className="col-md-2">
                        <h5>Actions</h5>
                      </div>
                    </div>
                    <hr />
                    {this.props.reports.map(item =>
                      <ReportRow item={item} firebase={this.props.firebase} savedReports={this.props.savedReports} key={item.key} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

class ReportRow extends Component {
  saveReport(key, name) {
    const { currentUser } = this.props.firebase.auth;
    const d = new Date(), time = d.getTime();
    const savedKey = 'SR' + time;

    this.props.firebase.savedReport(savedKey)
    .set({
      profile: {
        savedBy: currentUser.uid,
        savedKey,
        savedName: name,
        savedReportKey: key
      }
    });
  }

  deleteReport(key) {
    this.props.firebase.savedReport(key)
    .delete();
  }

  render() {
    const { item, savedReports } = this.props;
    const filteredSaved = savedReports.filter(report => report.key === item.key);
    return (
      <div className="row">
        <div className="col-md-4">
          <Link to={'/viewReport/' + item.key}>
            {item.reportName}
          </Link>
        </div>
        <div className="col-md-2">
          <a href={item.reportFile}>
            Download Link
          </a>
        </div>
        <div className="col-md-4">
          {dateFormat(new Date(item.reportCreatedDate), " #MMMM# #DD#, #YYYY#")}
        </div>
        <div className="col-md-2">
          {filteredSaved.length > 0 ?
            <button className="btn btn-danger btn-sm" onClick={() => this.deleteReport(filteredSaved[0].savedKey)}>
              Unsave Report
            </button>
          :
            <button className="btn btn-success btn-sm" onClick={() => this.saveReport(item.key, item.reportName)}>
              Save Report
            </button>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { reports, savedReports } = state.reports;

  return { reports, savedReports };
}

const condition = authUser => !!authUser;
withRouter(withFirebase(Reports))
export default withAuthorization(condition)(connect(mapStateToProps, { getReports })(Reports));
