import React from 'react';

import { withFirebase } from '../Firebase';

const SignOutButton = ({ firebase }) => (
  <button className="btn" type="button" onClick={firebase.doSignOut} style={{marginBottom: 0, lineHeight: 1}}>
    Sign Out
  </button>
);

export default withFirebase(SignOutButton);
